import {
  keyBy,
  sortBy,
  map,
  reduce,
  pickBy,
  toString,
  get,
  filter,
} from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import { getPermissions, getCurrentUserData } from '../../utils/selectors';
import { dateToFormat } from '../../utils/DateTime';
import { isCreateCrewRotationButtonVisible } from '../crew-list/selector';
import { getNoUserNameTextByStatus } from '../../utils/timeplans';

const didLoad = (s) => s.timeplans.didLoad;
const state = (s) => s.timeplans.state;
const getFilter = (s) => s.timeplans.filter;
const getAppliedFilter = (s) => s.timeplans.appliedFilter;
const crewListMode = (s) => s.crewList.mode;

export const getAssignedVesselsData = (s) => s.assignedVessels.data;
export const getVesselOwnersData = (s) => s.vesselOwners.data;
const getTimeplans = (s) => s.timeplans.data;

const getManningScaleById = createSelector(
  [getAssignedVesselsData],
  ({ manningScales }) => keyBy(manningScales, 'originId'),
);

const applyAdditionalFilter = (filterData, manningScales) => {
  const additionalFilter = get(filterData, 'additionalFilter', 'none');
  if (additionalFilter === 'none') {
    return manningScales;
  }
  const filteredManningScales = filter(manningScales, additionalFilter);
  return filteredManningScales;
};

const getManningScalesData = createSelector(
  [getAssignedVesselsData, getAppliedFilter],
  ({ manningScales }, stateFilter) => {
    const withAdditionalFilter = applyAdditionalFilter(stateFilter, manningScales);

    return withAdditionalFilter.reduce(
      (acc, ms) =>
        stateFilter[`roles.${ms.role_id}`] &&
        stateFilter[`vessels.${ms.vessel_id}`]
          ? {
            manningScales: [...acc.manningScales, ms],
            msCountByVesselId: {
              ...acc.msCountByVesselId,
              [ms.vessel_id]: (acc.msCountByVesselId[ms.vessel_id] || 0) + 1,
            },
          }
          : acc,
      {
        manningScales: [],
        msCountByVesselId: {}
      }
    );
  }
);

const getResources = createSelector(
  [getManningScalesData],
  ({ manningScales }) => manningScales,
);

const getVessels = createSelector(
  [getAssignedVesselsData, getManningScalesData],
  ({ assignedVesselsByVesselId }, { msCountByVesselId }) => map(msCountByVesselId, (rolesCount, vesselId) => ({
    ...assignedVesselsByVesselId[vesselId],
    rolesCount,
  }))
    .sort((a, b) => a.createdAt - b.createdAt),
);

const getOptionsOfVessels = createSelector(
  [getAssignedVesselsData, getFilter],
  ({ assignedVesselsByVesselId }, filterObj) => {

    const ownerIds = Object.keys(pickBy(filterObj, (v, k) => v && k.includes('owners')))
      .map((k) => k.split('.')[1]);

    const vessels = ownerIds.includes('all')
      ? assignedVesselsByVesselId
      : pickBy(assignedVesselsByVesselId, (v) => v.owner_id && ownerIds.includes(toString(v.owner_id)));

    return map(
      vessels,
      ({
        name,
        imo,
        createdAt,
        owner_id,
      }, vesselId) => ({
        value: filterObj[`vessels.${vesselId}`] && filterObj[`owners.${owner_id}`],
        field: `vessels.${vesselId}`,
        label: `${name} (${imo})`,
        createdAt,
        ownerId: owner_id && toString(owner_id),
      }),
    )
      .sort((a, b) => a.createdAt - b.createdAt);
  },
);

const getRolesBySelectedVessels = createSelector(
  [getFilter, getAssignedVesselsData],
  (filterData, { manningScales }) => manningScales
    .filter((ms) => filterData[`vessels.${ms.vessel_id}`])
    .reduce((acc, ms) => (
        !acc[ms.role_id] ? ({
          ...acc,
          [ms.role_id]: {
            value: filterData[`roles.${ms.role_id}`],
            field: `roles.${ms.role_id}`,
            label: ms.title,
            priority: ms.crewing_priority,
          },
        }) : acc),
      {}),
);

const getOptionsOfVesselOwners = createSelector(
  [getVesselOwnersData, getFilter],
  (data, filterData) => map(
    data,
    (owner) => ({
      value: filterData[`owners.${owner.id}`],
      field: `owners.${owner.id}`,
      label: owner.attributes.title,
    }),
  ),
);

const getOptionsOfRoles = createSelector(
  [getRolesBySelectedVessels],
  (rolesById) => sortBy(rolesById, 'priority'),
);

const isValidFilter = createSelector(
  [getFilter, getRolesBySelectedVessels],
  (filter, roleById) => {
    const {
      atLeastOneVesselSelected,
      atLeastOneRoleSelected
    } = reduce(filter, (acc, isSelected, propName) => {
      if (acc.atLeastOneVesselSelected && acc.atLeastOneRoleSelected) return acc;

      const [type, id] = propName.split('.');
      const nextAcc = {};

      if (acc.atLeastOneVesselSelected || (type === 'vessels' && isSelected)) {
        nextAcc.atLeastOneVesselSelected = true;
      }

      if (acc.atLeastOneRoleSelected || (type === 'roles' && isSelected && roleById[id])) {
        nextAcc.atLeastOneRoleSelected = true;
      }

      return nextAcc;
    }, {
      atLeastOneVesselSelected: false,
      atLeastOneRoleSelected: false
    });

    return atLeastOneVesselSelected && atLeastOneRoleSelected;
  },
);

export const getEvents = createSelector(
  [getTimeplans, getAssignedVesselsData, getManningScaleById, getCurrentUserData],
  (timeplans, { assignedVesselsByVesselId }, manningScaleById, currentUser) => timeplans.reduce((acc, {
    id,
    start,
    end,
    status,
    manning_scale_id,
    crew_change_sign_off_id: offChId,
    crew_change_sign_on_id: onChId,
    userName,
    promotionsCount,
    readinessDate,
    userId,
    avatar,
    port,
    lastComment,
    offer,
    vacancy_description,
    vacancy_id,
    to_publish,
    external_only,
    repair_team,
    has_contract_template,
    document_templates,
    has_shipman_comment,
    vessel_name,
    role_name,
    telegram_bot,
    last_comment_body
  }) => {
    const {
      name: role,
      originRoleId,
      originRoleName,
      role_id,
      vessel_id,
      originId,
      crewing_priority: rolePriority,
      createdAt: roleCreatedAt,
      template_offer,
    } = manningScaleById[manning_scale_id] || {};

    const isOnlyTimeplanView = currentUser.role === 'partner_view';
    let text = getNoUserNameTextByStatus(status, userName, promotionsCount);

    if (text === 'Vacancy') {
      if (to_publish && !isOnlyTimeplanView) text = `🌐 ${text}`;
      if (offer && !isOnlyTimeplanView) text = `${text} [$${offer}]`;
    } else if (text !== 'Other agency') {
      if (telegram_bot) text = `🤖 ${text}`;
    }

    if (repair_team) {
      text = `🛠️ ${text}`;
    }

    if (port) text = `(${dateToFormat(start, 'dd.MM')} ${port}) ${text}`;

    if (['to_propose', 'pending_approval', 'approved'].includes(status) && readinessDate) {
      text = `${text} - DOA ${dateToFormat(readinessDate, 'dd.MM')}`;
    }

    if (last_comment_body) text = `${text} - ${last_comment_body}`;

    const isVacancyPage = window.location.pathname.includes('vacancies');

    if (!originId && !isVacancyPage) return acc; // temp fix for timeplans fi archived manning scales

    return [
      ...acc,
      {
        id,
        offChId,
        onChId,
        start,
        end,
        text,
        resource: `${originId}:${role_id}:${vessel_id}`,
        msId: originId,
        // next data for the form
        status,
        vesselId: vessel_id,
        vessel: get(assignedVesselsByVesselId, `${vessel_id}.name`) || vessel_name,
        roleId: role_id,
        role: role || role_name,
        originRoleId,
        originRoleName,
        rolePriority,
        roleCreatedAt,
        userId,
        avatar,
        port,
        userName,
        promotionsCount,
        readinessDate,
        offer,
        vacancy_description,
        vacancy_id,
        to_publish,
        external_only,
        repair_team,
        template_offer,
        has_contract_template,
        document_templates,
        cssClass: [
          'timeplan',
          `timeplan__${status}`,
          `timeplan__${id}`,
          offChId ? 'timeplan__has_off' : '',
          onChId ? 'timeplan__has_on' : '',
          has_shipman_comment ? 'timeplan__shipman' : '',
        ].join(' '),
      },
    ];
  }, []),
);

export default createStructuredSelector({
  didLoad,
  state,
  filter: getFilter,
  resources: getResources,
  vessels: getVessels,
  assignedVessels: getAssignedVesselsData,
  optionsOfVessels: getOptionsOfVessels,
  optionsOfRoles: getOptionsOfRoles,
  optionsOfOwners: getOptionsOfVesselOwners,
  events: getEvents,
  permissions: getPermissions,
  isValidFilter,
  crewListMode,
  isCreateCrewRotationButtonVisible,
  currentUser: getCurrentUserData,
});
