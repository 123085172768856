import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions, constants } from "./redux";
import Api from "../../utils/Api";
import { constants as ownerDetailsConstants } from "../owner-details/redux";
import { actions as ownerDetailsActions } from "../owner-details/redux";
import { addSuccess, addError } from "../notifications/actions";
import { getLocation } from "connected-react-router";
import { matchPath } from "react-router-dom";
import { get } from "lodash";

function* fetchItems({ payload }) {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: "/owners/:id/recruiters" });
    if (!correctPath) return;

    yield put(actions.loadItemRequest()); // set is loading state

    const { data } = yield call(Api.owner.getRecruiters, payload.id);

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* assignRecruiter({ payload }) {
  const { id, relation, data, message, cb } = payload;

  try {
    yield call(Api.owner.addRelation, id, relation, data);

    yield put(ownerDetailsActions.loadItemRequest());

    yield put(addSuccess(message));
  } catch (e) {
    console.error(e);

    if (get(e, "apiErrors[0].status") === "400") {
      yield put(
        addError("User is already assigned as Crew Inspector for this owner")
      );
    }
  } finally {
    if (cb) cb();
  }
}

function* unassignRecruiter({ payload }) {
  const { id, relation, data, cb } = payload;

  try {
    yield call(Api.owner.deleteRelation, id, relation, data);

    yield put(ownerDetailsActions.loadItemRequest());

    yield put(addSuccess("The Crew Inspector is unassigned successfully"));
  } catch (e) {
    console.error(e);
  } finally {
    if (cb) cb();
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(ownerDetailsConstants.ADD_LOADED_ITEM, fetchItems),
    takeEvery(constants.ASSIGN_RECRUITER, assignRecruiter),
    takeEvery(constants.UNASSIGN_RECRUITER, unassignRecruiter),
  ];
}
