import { keyBy, get } from "lodash";
import { labelValue } from "../../utils";

const getRecruiters = (relationships, groupedIncluded) => {
  if (!relationships.recruiters) return null;

  const recruiters = relationships.recruiters.data.map((rec) => {
    const recruiter = groupedIncluded.clients.find((r) => r.id === rec.id);
    return {
      ...labelValue(recruiter, "attributes.name", "id"),
      role: recruiter?.attributes?.role,
    };
  });

  return recruiters;
};

const getCoordinator = (relationships, groupedIncluded) => {
  const coordinatorId = get(relationships, "coordinator.data.id");
  if (!coordinatorId) return null;

  const coordinatorData = groupedIncluded.clients.find(
    (r) => r.id === coordinatorId
  );

  return labelValue(coordinatorData, "attributes.name", "id");
};

const addRelationshipsToItem = (
  { id, type, attributes, relationships },
  groupedIncluded
) => {
  const data = {
    id,
    type,
    attributes,
    recruiters: getRecruiters(relationships, groupedIncluded),
    coordinator: getCoordinator(relationships, groupedIncluded),
    manager: labelValue(
      groupedIncluded.managers,
      "attributes.title",
      "managers.id"
    ),
    owner: labelValue(groupedIncluded.owners, "attributes.title", "owners.id"),
    manager_vessel: groupedIncluded.manager_vessels,
    owner_vessel: groupedIncluded.owner_vessels,
  };
  return data;
};

/**
 * convertItem
 * @param { Object } data - vessel
 * @param { Array } included - list of relationship items
 * @return { Object } item with relationships
 */
export const convertItem = ({ data, included }) => {
  const groupedIncluded = keyBy(included, (incl) => `${incl.type}`);
  const inclededClients = included
    ? included.filter((incl) => incl.type === "clients")
    : null;

  if (inclededClients) {
    groupedIncluded.clients = inclededClients;
  }

  return addRelationshipsToItem(data, groupedIncluded);
};

export default convertItem;
