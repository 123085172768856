import {
  call,
  put,
  takeEvery,
  delay,
  takeLatest,
  debounce,
  select,
} from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { actions, constants, selector } from "./redux";
import Api from "../../utils/Api";
import { addItem as notify } from "../notifications/actions";
import { deleteItem as deleteDialog } from "../dialog-manager/actions";

export function* fetchItems() {
  try {
    const featureState = yield select(selector);

    const data = yield call(Api.notificationManager.index, featureState);

    yield call(fetchNotificationCount);
    yield put(actions.addItems(data));
    yield call(markAllShown);
    yield call(fetchNotificationPopUp);
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* clearAllNotifications() {
  try {
    yield call(Api.notificationManager.clearAll);
    yield put(deleteDialog());
    yield call(fetchItems);
    yield call(fetchNotificationCount);

    yield put(
      notify({
        type: "success",
        title: "Success",
        message: "All notifications cleared successfully",
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* clearNotification({ payload }) {
  try {
    yield call(Api.notificationManager.markAsRead, payload);
    yield call(fetchItems);
    yield call(fetchNotificationCount);
  } catch (e) {
    console.error(e);
  }
}

export function* markAllShown() {
  try {
    yield call(Api.notificationManager.markAllShown);
  } catch (e) {
    console.error(e);
  }
}

export function* showPopUp({ payload }) {
  const { primary_text, secondary_text } = payload;

  const snackbarData = {
    type: "info",
    title: primary_text,
  };

  if (secondary_text) {
    snackbarData.message = secondary_text;
  }

  try {
    yield put(notify({ ...snackbarData }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchNotificationCount() {
  try {
    const { data } = yield call(Api.notificationManager.notificationCount);

    yield put(actions.setNotificationCount(data));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchNotificationCount() {
  while (true) {
    yield delay(60 * 1000);
    yield call(fetchNotificationCount);
  }
}

function* fetchNotificationPopUp() {
  try {
    const { data } = yield call(Api.notificationManager.notificationPopup);

    yield put(actions.setNotificationPopUp(data));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchNotificationPopUp() {
  while (true) {
    yield delay(180 * 1000);
    yield call(fetchNotificationPopUp);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 */
export default function getFeatureSagas() {
  return [
    takeEvery(
      [constants.LOAD_ITEMS_REQUEST, constants.CHANGE_PAGE_NUMBER],
      fetchItems
    ),
    takeEvery(LOCATION_CHANGE, fetchNotificationCount),
    takeEvery(LOCATION_CHANGE, fetchNotificationPopUp),
    takeLatest(constants.SET_NOTIFICATION_COUNT, watchFetchNotificationCount),
    takeLatest(constants.SET_NOTIFICATION_POP_UP, watchFetchNotificationPopUp),
    takeEvery(constants.CLEAR_ALL_NOTIFICATIONS, clearAllNotifications),
    takeEvery(constants.CLEAR_NOTIFICATION, clearNotification),
    debounce(3000, constants.SHOW_POP_UP, showPopUp),
  ];
}
